import { Container, Row, Col, Card, Button } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaArrowRightLong } from "react-icons/fa6";
import {
  allEvseAction,
  cacheRefreshApi,
  fetchEvseCardCountAction,
  fetchEVSEPercentageChangeAction,
  firstClearCacheApi,
  saveRoamingStation,
  secondClearCacheApi,
} from "../../../../store/actions/evseAction";
import EVSEList from "./evseList";
import { ImCross } from "react-icons/im";
import Popup from "reactjs-popup";
import * as Yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import EVSEMap from "./viewEvseMap";

function ViewEVSE(props) {
  const { t } = useTranslation();
  const { role } = JSON.parse(localStorage.getItem("user"));
  const latitude = process.env.REACT_APP_COUNTRY_LATITUDE;
  const longitude = process.env.REACT_APP_COUNTRY_LONGITUDE;
  // console.log("latitude: " + latitude + ", longitude: " + longitude);

  useEffect(() => {
    props.all();
    props.fetchEvseCardCountApi();
    // props.fetchEvsePercentageChange();
  }, []);

  const cardData = props.allEvseCardCountRes && props.allEvseCardCountRes;
  // console.log("cardData: ", cardData)

  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(4.8);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        setZoom(4.3);
      },
      (error) => {
        console.error("Error getting user location:", error);
        setUserLocation({
          lat: Number(latitude),
          lng: Number(longitude),
        });
        setZoom(12);
      }
    );
  }, []);

  const AddRoamingSchema = Yup.object().shape({
    roaming_station: Yup.array().of(
      Yup.object().shape({
        stationid: Yup.string().required("Roaming station is required"),
      })
    ),
  });

  if (props.addRoaming.statuscode === 200) {
    alert("Roaming Stations have been added successfully");
    window.location.reload();
  }

  const total = props.list && props.list.data?.filter((item) => item.status !== "Discovery")?.length;
  const activec = props.list && props.list.data?.filter((lc) => lc.status === "Available");
  const comingsoon = props.list && props.list.data?.filter((lc) => lc.status === "Coming soon");
  const maintenance = props.list && props.list.data?.filter((lc) => lc.status === "Maintenance");
  // console.log("station list", props.list && props.list)

  const Total_location_percentage = props.percentagechange && props.percentagechange.total_location_percentage;
  const Total_location_change_symbol = props.percentagechange && props.percentagechange.change_symbol;

  const Available_location_percentage = props.percentagechange && props.percentagechange.available_percentage;
  const Available_location_change_symbol = props.percentagechange && props.percentagechange.available_change_symbol;

  const Comingsoon_percentage = props.percentagechange && props.percentagechange.comingsoon_percentage;
  const Comingsoon_change_symbol = props.percentagechange && props.percentagechange.comingsoon_change_symbol;

  const Inoperative_percentage = props.percentagechange && props.percentagechange.inoperative_percentage;
  const Inoperative_change_symbol = props.percentagechange && props.percentagechange.inoperative_change_symbol;

  const handleClear = () => {
    props.clearCacheApi();
    console.log("Clear Cache");
  };

  useEffect(() => {
    if (props.clearCacheRes.statuscode === 200) {
      alert("Cache is cleared");
      window.location.reload();
    } else if (props.clearCacheRes.statuscode === 405) {
      alert("Error while refreshing stations.");
      window.location.reload();
    }
  }, [props.clearCacheRes]);

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />

      <Row className="mt-2">
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard card-custom total-rules">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL LOCATIONS")}</Card.Text>
              <Card.Title>
                <b>{total !== undefined ? total : 0}</b>
              </Card.Title>
            </div>
          </Card> */}
          {/* <div className="customercard cardbg" style={{ width: "70%" }}> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total locations")}</p>
            </div>
            <Card.Title>
              <b>{total !== undefined ? total : 0}</b>
            </Card.Title>
          </Card>
          {/* </div> */}
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard card-custom total-active-rules">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL CHARGERS / CONNECTORS")}</Card.Text>
              <Card.Title>
                <b>
                  {cardData ? cardData.total : 0} / {cardData ? cardData.connectors_total : 0}
                </b>
              </Card.Title>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total chargers / Connectors")}</p>
            </div>
            <Card.Title>
              <b>
                {cardData ? cardData.total : 0} / {cardData ? cardData.connectors_total : 0}
              </b>
            </Card.Title>
          </Card>
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard card-custom total-inactive">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL AC CONNECTORS")}</Card.Text>
              <Card.Title>
                <b>{cardData ? cardData.total_ac_connectors : 0}</b>
              </Card.Title>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total AC Connectors")}</p>
            </div>
            <Card.Title>
              <b>{cardData ? cardData.total_ac_connectors : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </Col>
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard card-custom total-draft">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL DC CONNECTORS")}</Card.Text>
              <Card.Title>
                <b>{cardData ? cardData.total_dc_connectors : 0}</b>
              </Card.Title>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total DC Connectors")}</p>
            </div>
            <Card.Title>
              <b>{cardData ? cardData.total_dc_connectors : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Card className="customercard">
            <div>
              <EVSEMap stations={props.list?.data} center={userLocation} zoom={zoom} />
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="d-flex justify-content-between">
              <div className={`table-header d-flex align-items-center `}>{t("EVSE List")}</div>
              <button
                className="lgn-btn btn"
                type="button"
                style={{ position: "relative", width: "auto", height: "auto" }}
                onClick={handleClear}
              >
                {t("Refresh Cache")}
              </button>
            </div>
            {props.loading ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}
              >
                Loading data...
              </h3>
            ) : (
              props.list && <EVSEList {...props}></EVSEList>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.allevse,
    allEvseCardCountRes: state.evse.allEvseCardCount,
    percentagechange: state.evse.evsepercentagechange,
    addRoaming: state.evse.saveRoamingStation,
    clearCacheRes: state.evse.clearCache,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
    fetchEvseCardCountApi: () => dispatch(fetchEvseCardCountAction()),
    fetchEvsePercentageChange: () => dispatch(fetchEVSEPercentageChangeAction()),
    clearCacheApi: () => dispatch(cacheRefreshApi()),
    addRoamingStation: (stationid) => dispatch(saveRoamingStation(stationid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewEVSE);
