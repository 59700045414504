import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchHeatMapGraph } from "../../../../store/actions/insightAction";
import "react-datepicker/dist/react-datepicker.css";
import ApexCharts from "react-apexcharts";

function EnergyHeatMap(props) {

  useEffect(() => {
    props.getHeatMapGraph();
  }, [])


  const data = props.heat_map && props.heat_map.data;
  // console.log(data)


  const month1 = data && data.filter((item) => item.month === 1);
  const month2 = data && data.filter((item) => item.month === 2);
  const month3 = data && data.filter((item) => item.month === 3);
  const month4 = data && data.filter((item) => item.month === 4);
  const month5 = data && data.filter((item) => item.month === 5);
  const month6 = data && data.filter((item) => item.month === 6);
  const month7 = data && data.filter((item) => item.month === 7);
  const month8 = data && data.filter((item) => item.month === 8);
  const month9 = data && data.filter((item) => item.month === 9);
  const month10 = data && data.filter((item) => item.month === 10);
  const month11 = data && data.filter((item) => item.month === 11);
  const month12 = data && data.filter((item) => item.month === 12);

  // console.log("month1:", month1);
  const monthData1 = [...month1].map((item) => (item.days_data))
  const monthData2 = [...month2].map((item) => (item.days_data))
  const monthData3 = [...month3].map((item) => (item.days_data))
  const monthData4 = [...month4].map((item) => (item.days_data))
  const monthData5 = [...month5].map((item) => (item.days_data))
  const monthData6 = [...month6].map((item) => (item.days_data))
  const monthData7 = [...month7].map((item) => (item.days_data))
  const monthData8 = [...month8].map((item) => (item.days_data))
  const monthData9 = [...month9].map((item) => (item.days_data))
  const monthData10 = [...month10].map((item) => (item.days_data))
  const monthData11 = [...month11].map((item) => (item.days_data))
  const monthData12 = [...month12].map((item) => (item.days_data))
  // console.log("month2:", monthData2);
  //////////////////////////////////////////////////////////////

  const allDaysData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData1.forEach((dayData) => {
    allDaysData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allFebData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData2.forEach((dayData) => {
    allFebData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allMarData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData3.forEach((dayData) => {
    allMarData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allAprilData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData4.forEach((dayData) => {
    allAprilData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allMayData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData5.forEach((dayData) => {
    allMayData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allJuneData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData6.forEach((dayData) => {
    allJuneData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allJulyData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData7.forEach((dayData) => {
    allJulyData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allAugData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData8.forEach((dayData) => {
    allAugData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allSepData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData9.forEach((dayData) => {
    allSepData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allOctData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData10.forEach((dayData) => {
    allOctData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allNovData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData11.forEach((dayData) => {
    allNovData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });

  const allDecData = Array.from({ length: 31 }, (_, index) => ({
    x: index + 1,
    y: 0,
  }));

  monthData12.forEach((dayData) => {
    allDecData[dayData.day - 1] = {
      x: dayData.day,
      y: dayData.energy,
    };
  });


  const cities = {
    options: {
      chart: {
        height: 550,
        type: 'heatmap',
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: true,
          distributed: true,
          colorScale: {
            ranges: [{
              from: 0,
              to: 5,
              name: 'low',
              color: '#afeaaf'
            },
            {
              from: Math.ceil(5),
              to: 10,
              name: 'medium',
              color: '#61e529'
            },
            {
              from: Math.ceil(10),
              to: 15,
              name: 'high',
              color: '#24ae24'
            },
            {
              from: Math.ceil(16),
              to: 8000,
              name: 'extreme',
              color: '#05472A'
            }
            ]
          }
        },

      },
      xaxis: {
        labels: {
          show:false,
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },              
      },
      yaxis:{
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },      
      },
      legend: {
        position: "top",
        labels: {
          colors: "#FFFFFF", // Replace with the desired color for legend labels
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        followCursor: false,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '12px',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },

    },

    series: [
      {
        name: 'Jan',
        data: allDaysData,

      },
      {
        name: 'Feb',
        data: allFebData,

      },
      {
        name: 'Mar',
        data: allMarData,

      },
      {
        name: 'Apr',
        data: allAprilData,

      },
      {
        name: 'May',
        data: allMayData,

      },
      {
        name: 'Jun',
        data: allJuneData,

      },
      {
        name: 'July',
        data: allJulyData,

      },
      {
        name: 'Aug',
        data: allAugData,

      },
      {
        name: 'Sep',
        data: allSepData,

      },
      {
        name: 'Oct',
        data: allOctData,

      },
      {
        name: 'Nov',
        data: allNovData,

      },
      {
        name: 'Dec',
        data: allDecData,

      },
    ],
  }

  return (
    <>

      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }} >Loading data...</h3> :
        data && data.length > 0 ? (

          <ApexCharts
            options={cities.options}
            series={cities.series}
            type="heatmap"
            height={587}
          />) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}
          >
            <h3>No data found </h3>
          </div>
        )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    heat_map: state.insight.heatmap_insight,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHeatMapGraph: () => dispatch(fetchHeatMapGraph()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnergyHeatMap);
