import React from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../breadcrums";
import { AiOutlineQrcode } from "react-icons/ai"
import "../evse.css";

import { fetchStationV2EvseAction } from '../../../../store/actions/evseAction';
import { useTranslation } from 'react-i18next';
const ViewSingleStationV2Component = (props) => {
    const { t } = useTranslation();
    const evseSchema = Yup.object().shape({
        location: Yup.string().required('Location is required'),
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City  is required'),
        state: Yup.string().required('State/Province  is required'),
        country: Yup.string().required('Country is required'),
        postal_code: Yup.string().required('Zip code is a required').typeError('Zip code can only be a number'),
        latitude: Yup.number().required('Latitude code is a required').typeError('Latitude code can only be a number'),
        longitude: Yup.number().required('Longitude code is a required').typeError('longitude code can only be a number'),
        operator: Yup.string().required('Organisation is required'),
        network: Yup.string().required('Network is required'),
        category: Yup.string().required('Network Category is required'),
        parkingtype: Yup.string().required('Parking Type is required'),
        free_charging: Yup.boolean().required('Free Charging field is required'),
        ocpi: Yup.bool().required('Ocpi is required'),
        description: Yup.string().required('Description is Required'),
        amenities: Yup.array().of(
            Yup.object().shape({
                amenity: Yup.bool().oneOf([true], 'Please select atleast one aminity'),
            })),
        evses: Yup.array().of(
            Yup.object().shape({
                evse_id: Yup.string().required('EVSE ID is required'),
                charger_name: Yup.string().required('Charger Name is required'),
                ocpp: Yup.string().required('OCPP Version is required'),
            })),
        connectors: Yup.array().of(
            Yup.object().shape({
                evse_id: Yup.string().required('EVSE ID is required'),
                id: Yup.string().required('Connector ID is required'),
                standard: Yup.string().required('Connector Type is required'),
                speed: Yup.string().required('Connector Charging Speed is required'),
                format: Yup.string().required('Connector Power is required'),
                power_type: Yup.string().required('Connector Power Type is required'),
                max_amperage: Yup.number().required('Max Ampere is required').typeError('Max Ampere is required'),
                max_voltage: Yup.number().required('Max Voltage is required').typeError('Max Voltage is required'),
            })),
        prices: Yup.array().of(
            Yup.object().shape({
                price: Yup.string().required('Price Name  is required'),
                status: Yup.string().required('Status is required'),
                publish: Yup.bool().required('Publish is required'),
            })),



    });

    const { register, getValues, setValue, formState: { errors } } = useForm({ resolver: yupResolver(evseSchema), mode: "all" });
    const { stationid } = useParams()
    useEffect(() => {
        props.fetch(stationid);
    }, [])
    const data = props.fetchstation && props.fetchstation
    // console.log(data);

    useEffect(() => {
        const { location, city, state, country, free_charging, description, address, latitude, location_sub_type, location_type, longitude, operator, parking_type, postal_code, connectors, amenities, evses, prices, ocpi } = data
        if (data) {
            setValue("location", location, { shouldValidate: true });
            setValue("city", city, { shouldValidate: true });
            setValue("state", state, { shouldValidate: true });
            setValue("country", country, { shouldValidate: true });
            setValue("address", address, { shouldValidate: true });
            setValue("latitude", latitude, { shouldValidate: true });
            setValue("longitude", longitude, { shouldValidate: true });
            setValue("network", location_type, { shouldValidate: true });
            setValue("category", location_sub_type, { shouldValidate: true });
            setValue("operator", operator, { shouldValidate: true });
            setValue("parkingtype", parking_type, { shouldValidate: true });
            setValue("postal_code", postal_code, { shouldValidate: true });
            setValue("connectors", connectors, { shouldValidate: true });
            setValue("evses", evses, { shouldValidate: true });
            setValue("prices", prices, { shouldValidate: true });
            setValue("amenities", amenities, { shouldValidate: true });
            setValue("free_charging", free_charging, { shouldValidate: true });
            setValue("description", description, { shouldValidate: true });

            if (ocpi) {
                setValue("ocpi", "yes");
            } else {
                setValue("ocpi", "No");
            }
        }
    }, [data], setValue)

    const array = getValues("amenities") && getValues("amenities");
    const chargers = getValues("evses") && getValues("evses");
    const connectors = getValues("connectors") && getValues("connectors");
    // console.log(connectors)
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={12}>
                    <form onSubmit={e => e.preventDefault()}>
                        <Card className='tariffcard'>
                            <div>
                                <h4 className='text-center p-3'>{t("EVSE Details")}</h4>
                            </div>
                            <Card.Body>
                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputGridname'>{t("Location Name")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.location ? 'is-invalid' : ''}`} name="location" id="floatingInputGridname" placeholder={t("Location Name")} {...register("location")} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputGridAddress'>{t("Address")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.address ? 'is-invalid' : ''}`} name="location" id="floatingInputGridAddress" placeholder={t("Address")} {...register("address")} disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputCountry'>{t("Country")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.country ? 'is-invalid' : ''}`} name="country" id="floatingInputCountry" placeholder={t("Country")}  {...register("country")} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <div className="form-floating">
                                                <label htmlFor='floatingInputState'>{t("State")}</label>
                                                <input type="text" className={`form-control gray-border ${errors.state ? 'is-invalid' : ''}`} name="state" id="floatingInputState" placeholder="Enter State/Province"  {...register("state")} disabled />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputcity'>{t("City")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.city ? 'is-invalid' : ''}`} name="city" id="floatingInputcity" placeholder="Enter City"  {...register("city")} disabled />

                                        </div>
                                    </div>
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputzip'>{t("Zipcode")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.postal_code ? 'is-invalid' : ''}`} name="postal_code" id="floatingInputzip" placeholder="Enter location zip code"  {...register("postal_code")} disabled />

                                        </div>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputGridlat'>{t("Latitude")}</label>
                                            <input type="number" className={`form-control gray-border ${errors.latitude ? 'is-invalid' : ''}`} name="latitude" id="floatingInputGridlat" placeholder="Enter latitude" {...register("latitude")} disabled />

                                        </div>
                                    </div>
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputlong'>{t("Longitude")}</label>
                                            <input type="number" className={`form-control gray-border ${errors.longitude ? 'is-invalid' : ''}`} name="longitude" id="floatingInputlong" placeholder="Enter longitude" {...register("longitude")} disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingSelectGridnetwork'>{t("Network Type")}</label>
                                            <select className={`form-select form-control`} name="network" id="floatingSelectGridnetwork" disabled>
                                                <option value={getValues("network")}>{getValues("network")}</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingSelectGridcategory'>{t("Sub-Network Category")}</label>
                                            <select className={`form-select  form-control`} name="category" id="floatingSelectGridcategory" disabled>
                                                <option value={getValues("category")}>{getValues("category")}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingSelectGridfree_charging'>{t("Is it free charging?")}</label>
                                            <select className={`form-select  form-control`} name="category" id="floatingSelectGridfree_charging" disabled>
                                                <option value={props.loadingevse ? "" : getValues("free_charging") === true ? "Yes" : "No"}>{getValues("free_charging") === true ? "Yes" : "No"}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputoperator'>{t("Select Organisation")}</label>
                                            <input type="input" className={`form-control gray-border ${errors.operator ? 'is-invalid' : ''}`} name="operator" id="floatingInputoperator" placeholder="Enter Organisation" {...register("operator")} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputparkingtype'>{t("Parking Type")}</label>
                                            <input type="input" className={`form-control gray-border ${errors.parkingtype ? 'is-invalid' : ''}`} name="parkingtype" id="floatingInputparkingtype" placeholder="Enter parkingtype" {...register("parkingtype")} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md p-2">
                                        <div className="form-floating">
                                            <div className="form-floating">
                                                <label htmlFor='floatingInputocpi'>{t("OCPI Enabled")} ?</label>
                                                <input type="input" className={`form-control gray-border ${errors.ocpi ? 'is-invalid' : ''}`} name="ocpi" id="floatingInputocpi" placeholder="Enter OCPI" {...register("ocpi")} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md p-2'>
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputQrcode'>{t("QR Code")}</label>
                                            <input type="input" className={`form-control gray-border`} name="ocpi" id="floatingInputQrcode" placeholder="QR code" value={data?.qrcode || ''} disabled />
                                        </div>
                                    </div>

                                </div>
                                <div className="row g-2">
                                    <div className="col-md p-2">
                                        <p><b>{t("Select Amenities")}</b></p>
                                        {array && array.map((item, index) => (
                                            <div key={index} className="form-check form-check-inline">
                                                <label className="form-check-label" htmlFor={"inlineCheckbox" + index}>{item}</label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div className='row g-2 mt-3'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label htmlFor="floatingTextarea">{t("Description")}</label>
                                            <textarea className="form-control" placeholder="Description" name="description" id="floatingTextarea" style={{ "height": "100px" }} {...register("description")} disabled></textarea>
                                        </div>
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>

                        {/* ------------Charger rendering------------- */}

                        <div>
                            <Row className="mt-2">
                                <Col>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active" aria-current="page">{t("View Chargers")}</li>
                                        </ol>
                                    </nav>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                            <div className="table-responsive">
                                                <table className="table table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='textblack'  scope="col">{t("Charge Box ID")}</th>
                                                            <th className='textblack' scope="col">{t("Charger Name")}</th>
                                                            <th className='textblack' scope="col">{t("OCPP Version")}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {chargers && chargers.map((charger, index) =>
                                                            <tr key={index}>
                                                                <td> {charger.evse_id}</td>
                                                                <td> {charger.charger_name}</td>
                                                                <td> {charger.ocpp}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active" aria-current="page">{t("Connector List")}</li>
                                        </ol>
                                    </nav>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                            <div className="table-responsive">
                                                <table className="table table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='textblack' scope="col">{t("Charge Box ID")}</th>
                                                            <th className='textblack' scope="col">{t("Connector ID ")}</th>
                                                            <th className='textblack' scope="col">{t("Connector Type")}</th>
                                                            <th className='textblack' scope="col">{t("Connector Charging Speed ")}</th>
                                                            <th className='textblack' scope="col">{t("Connector Energy Capacity")}</th>
                                                            <th className='textblack' scope="col">{t("Connector Power Type ")}</th>
                                                            <th className='textblack'  scope="col">{t("Max Ampere")}</th>
                                                            <th className='textblack' scope="col">{t("Max Voltage")}</th>
                                                            <th className='textblack' scope="col">{t("Status")}</th>
                                                            <th className='textblack' scope="col">{t("QR Code")}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {connectors && connectors.map((subItems) => {
                                                            return (
                                                                <tr key={subItems.id}>
                                                                    <td>{subItems.evse_id} </td>
                                                                    <td> {subItems.id}</td>
                                                                    <td> {subItems.standard}</td>
                                                                    <td> {subItems.speed}</td>
                                                                    <td> {subItems.format}</td>
                                                                    <td> {subItems.power_type}</td>
                                                                    <td> {subItems.max_amperage}</td>
                                                                    <td> {subItems.max_voltage}</td>
                                                                    <td> {subItems.status}</td>
                                                                    <td><center><a href={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${subItems.evse_id},${subItems.id}`} target="_blank" rel="noreferrer noopener"><AiOutlineQrcode size="35px" color="black" /></a></center></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active" aria-current="page">{t("Tariff List")}</li>
                                        </ol>
                                    </nav>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                            <div className="table-responsive">
                                                <table className="table table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='textblack' scope="col">{t("Tariff Name")} </th>
                                                            <th className='textblack' scope="col">{t("Tariff Description")}</th>
                                                            <th className='textblack' scope="col">{t("Tariff Price")}</th>
                                                            <th className='textblack' scope="col">{t("Tariff Currency")}</th>
                                                            <th className='textblack' scope="col">{t("Tariff Status")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.price_components &&
                                                            <tr key={11}>
                                                                <td>{data.price_components.name} </td>
                                                                <td> {data.price_components.description}</td>
                                                                <td> {data.price_components.components && data.price_components.components.map((item, index) => (
                                                                    <ol key={index} style={{ listStyleType: 'none' }}>
                                                                        <li > {t("Amount")}: {item.amount} ,Unit: {item.unit}</li>
                                                                    </ol>
                                                                ))}
                                                                </td>
                                                                <td> {data.price_components.currency}</td>
                                                                <td> {data.price_components.status}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchStationV2EvseAction(stationid)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleStationV2Component)