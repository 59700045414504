import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SingleOrgTariffAction } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";

function ViewSingleOrganisation(props) {
    const { t } = useTranslation();
    const { id } = useParams()
    useEffect(() => {
        props.getSingleOrgData(id);
    }, []);
    const data = (props.fetchSingleOrgDetails && Array.isArray(props.fetchSingleOrgDetails.organisations) && props.fetchSingleOrgDetails?.organisations?.length > 0)
        ? props.fetchSingleOrgDetails.organisations?.[0]
        : null;  // Return null or handle the case where the data is missing

    // console.log(data);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("User Details")} </span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Name")}</label>
                                        <input className="form-control gray-border" type="text" name="name" id="floatingInputGridName" placeholder="Name" defaultValue={data.name} disabled />
                                    </div>
                                </Col>

                                <Col xs={12} md={6}  >
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Email")}</label>
                                        <input className="form-control gray-border" type="email" name="email" id="floatingInputGridemail" placeholder="Email" defaultValue={data.email} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} md={6} className="mt-2">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Address")}</label>
                                        <input className="form-control gray-border" type="text" name="address" id="floatingInputGridaddress" placeholder="Address" defaultValue={data.address} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className="mt-2">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Zipcode")}</label>
                                        <input className="form-control gray-border" type="text" name="postal_code" id="floatingInputGridpostal_code" placeholder="Zip code" defaultValue={data.postal_code} disabled />
                                    </div>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} md={3} className="mt-2">
                                    <div>
                                        <label htmlFor="floatingInputGridPhone">{t("Mobile No.")}</label>
                                        <input className="form-control gray-border" type="text" name="phone" id="floatingInputGridPhone" defaultValue={data.phone} disabled />
                                    </div>
                                </Col>

                                <Col xs={12} md={3} className="mt-2">
                                    <div>
                                        <label htmlFor="floatingInputGridUnitPrice">{t("Electricity per unit price")}</label>
                                        <input className="form-control gray-border" type="text" name="per_unit_price" id="floatingInputGridUnitPrice" defaultValue={data.per_unit_price} disabled />
                                    </div>
                                </Col>

                                <Col xs={12} md={3} className="mt-2">
                                    <div>
                                        <label htmlFor="floatingInputGridIsFleet">{t("Is Fleet ?")}</label>
                                        <input className="form-control gray-border" type="text" name="isfleet" id="floatingInputGridIsFleet" defaultValue={data.isfleet} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div>
                                        <label className="mt-2" htmlFor="floatingInputGridStripeAccount">{t("Selected Stripe Account")}</label>
                                        <input className="input gray-border" type="text" name="stripe_account" id="floatingInputGridStripeAccount" placeholder="Selected Stripe Account" defaultValue={data?.stripe_account} disabled />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("City")}</label>
                                        <input className="form-control gray-border" type="text" name="city" id="floatingInputGridcity" placeholder="City" defaultValue={data.city} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("State")}</label>
                                        <input className="form-control gray-border" type="text" name="state" id="floatingInputGridstate" placeholder="State" defaultValue={data.state} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Country")}</label>
                                        <input className="form-control gray-border" type="text" name="country" id="floatingInputGridcountry" placeholder="Country" defaultValue={data.country} disabled />
                                    </div>
                                </Col>

                            </Row>
                            <Row className="mt-2" >
                                <Col xs={12} md={3}>
                                    <div >
                                        <label className="mt-2" htmlFor="floatingInputGridClientRevenueSharing">{t("Partner Revenue Sharing")} ( <b>%</b> )</label>
                                        <input className="form-control gray-border" type="text" name="client_revenue_sharing" id="floatingInputGridClientRevenueSharing" placeholder="Client Revenue Share" defaultValue={data.client_revenue_share} disabled />
                                    </div>
                                </Col>
                                <br />
                                <Col xs={12} md={3}>
                                    <div >
                                        <label className="mt-2" htmlFor="floatingInputGridRegionalRevenueSharing">{t("Regional Revenue Sharing")} ( <b>%</b> )</label>
                                        <input className="input gray-border" type="text" name="client_revenue_sharing" id="floatingInputGridRegionalRevenueSharing" placeholder="Regional Revenue Share" defaultValue={data?.regional_revenue_share} disabled />
                                    </div>
                                </Col>
                                <br />
                                <Col xs={12} md={3}>
                                    <div>
                                        <label className="mt-2" htmlFor="floatingInputGridSelfRevenueSharing">{t("Self Revenue Sharing")} ( <b>%</b> )</label>
                                        <input className="form-control gray-border" type="text" name="self_revenue_sharing" id="floatingInputGridSelfRevenueSharing" placeholder="Self Revenue Share" defaultValue={data.self_revenue_share} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div>
                                        <label className="mt-2" htmlFor="floatingInputGridStatus">{t("Status")}</label>
                                        <input className="form-control gray-border" type="text" name="status" id="floatingInputGridStatus" placeholder="Status" defaultValue={data.status} disabled />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        fetchSingleOrgDetails: state.operator.singleOrgTariff,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleOrgData: (id) => dispatch(SingleOrgTariffAction(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleOrganisation));