import React, { useState } from "react";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  fetchAllOcppNotificationStatusDetailAction,
  fetchEmailCofig,
  fetchEmailDuration,
  fetchOCPPNotificationStatusDetailAction,
  saveConfigured,
} from "../../../../store/actions/evseAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import Loader from "react-loader-spinner";
import { ImCross } from "react-icons/im";
import Popup from "reactjs-popup";
import { FcDataConfiguration } from "react-icons/fc";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import { Bounce, ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import CustomPaginationTable from "../../report/adminReport/customPaginationTable";
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";
// import { format, addMinutes, parseISO } from "date-fns";

function OCPPError(props) {
  const { t } = useTranslation();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const data = props.list && props.list.data;
  // console.log("data", data);
  const allData = props.fetchAllNotificationListRes && props.fetchAllNotificationListRes.data;
  // console.log("all data", allData);
  const reportCount = props.list && props.list.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(reportCount / defaultPageSize);

  const configuredSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    retries: Yup.number().required("Retries number is required").typeError("Attempt number is required"),
    retryInterval: Yup.number().required("retryInterval number is required").typeError("Duration is required"),
    organisation: Yup.string().required("Organisation is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(configuredSchema), mode: "all" });

  useEffect(() => {
    props.fetch10OcppNotification(currentPage, pageSize);
    props.fetchAllOcppNotification(currentPage, reportCount);
    props.getEmailDuration();
    props.fetchConfig();
    props.getOrg();
  }, [currentPage, pageSize, reportCount]);

  const emailConfig = props.fetchPrevEmailConfig && props.fetchPrevEmailConfig;
  // console.log("emailConfig ", emailConfig)

  useEffect(() => {
    const { email, attempts, duration, organisation } = emailConfig;
    if (email !== "" && attempts !== 0 && duration !== 0) {
      setValue("email", email);
      setValue("retries", attempts);
      setValue("retryInterval", duration);
      setValue("organisation", organisation);
    }
  }, [emailConfig]);

  const onSubmit = async (data) => {
    // console.log(data)
    props.saveConfig(data);
  };

  const columns = useMemo(
    () => [
      { Header: "Charge Point", accessor: "evse_id" },
      { Header: "Connector", accessor: "portno" },
      { Header: "Code", accessor: "error_code" },
      { Header: "Vendor ID", accessor: "vendor_id" },
      { Header: "Vendor Code", accessor: "vendor_error_code" },
      { Header: "Description", accessor: "info" },
      {
        Header: "Occurred at",
        accessor: "date_time",
        Cell: (cell) => {
          const slicedDate = cell.cell.split("+")[0];
          return slicedDate;
        },
      },
      { Header: "Status", accessor: "status" },
      { Header: "Required on site?", accessor: "site" },
    ],
    []
  );

  useEffect(() => {
    if (props.updateConfig.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("configuration is updated successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.updateConfig.statuscode === 201) {
      setIsToastVisible(true);
      toast.success(`Configuration is created successfully`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.updateConfig]);

  const handleOrganisation = (e) => {
    const code = e.value;
    // console.log("organisation", code);
    setValue("organisation", code);
  };

  const organisationOptions =
    props.orgdata &&
    props.orgdata.organisations?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header d-flex justify-content-between">
              {t("On-site service and error notification details")}

              <span className="mr-3">
                <Popup
                  trigger={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FcDataConfiguration style={{ fontSize: "40px", cursor: "pointer" }} />
                      <p>{t("Email Configure")}</p>
                    </div>
                  }
                  modal
                  contentStyle={{
                    borderRadius: "10px",
                    paddingTop: "10px",
                    paddingBottom: "50px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    width: "80%",
                    margin: "auto",
                    maxHeight: "100vh",
                    overflow: "auto",
                  }}
                  className="popup-content"
                >
                  {(close) => (
                    <>
                      {isToastVisible && <div className="overlay" />}
                      <ToastContainer
                        position="top-center"
                        autoClose={20}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        style={{ top: "10%", width: "auto", zIndex: "9999" }}
                      />
                      <div style={{ textAlign: "right" }}>
                        <button className="close-btn" onClick={close}>
                          <span>
                            <ImCross></ImCross>
                          </span>
                        </button>
                      </div>
                      <div className="tariffcard">
                        <h2 style={{ textAlign: "center" }}> {t("Update configuration")} </h2>
                        <Row className="add-roaming-station">
                          <Col lg={12}>
                            <form className="p-3 mt-4 d-flex" onSubmit={(e) => e.preventDefault()}>
                              <Col className="col-3">
                                <div className="form-floating">
                                  <label className="w-100">{t("Email")}</label>
                                  <input
                                    type="email"
                                    className={`form-control  gray-border ${errors.email ? "is-invalid" : ""}`}
                                    name="email"
                                    id="floatingInputGridemail"
                                    placeholder={t("Email")}
                                    {...register("email")}
                                  />
                                  <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                              </Col>
                              <Col className="col-3 form-floating ">
                                <label className="w-100">{t("Attempts")}</label>
                                <select
                                  className={`form-select form-control pr-2 ${errors.retries ? "is-invalid" : ""}`}
                                  name="retries"
                                  id="floatingSelectGridretries"
                                  {...register("retries")}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                </select>
                                <div className="invalid-feedback">{errors.retries?.message}</div>
                              </Col>
                              <Col className="col-3 form-floating ">
                                <label className="" style={{ width: "111%" }}>
                                  {t("Duration Interval")}
                                </label>
                                <select
                                  className={`form-select  form-control ${errors.retryInterval ? "is-invalid" : ""}`}
                                  name="retryInterval"
                                  id="floatingSelectGridretryInterval"
                                  {...register("retryInterval")}
                                >
                                  {props.fetchEmailDuration &&
                                    props.fetchEmailDuration.duration &&
                                    props.fetchEmailDuration.duration.map((time, k) => (
                                      <option key={time.key} value={time.key}>
                                        {time.value}
                                      </option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">{errors.retryInterval?.message}</div>
                              </Col>
                              <Col className="col-3 form-floating">
                                <div style={{ paddingRight: "10px" }}>
                                  <label className="w-100">{t("Organisation")}</label>
                                  <Select
                                    options={organisationOptions ? organisationOptions : []}
                                    className={`${errors.organisation ? "is-invalid" : ""}`}
                                    name="organisation"
                                    id="floatingInputGridOrganisation"
                                    placeholder={
                                      getValues("organisation") ? getValues("organisation") : "Select Organisation"
                                    }
                                    styles={DarkThemeStyles}
                                    // menuPortalTarget={document.body}
                                    onChange={handleOrganisation}
                                  />
                                  <div className="invalid-feedback">{errors.organisation?.message}</div>
                                </div>
                              </Col>
                            </form>
                          </Col>
                        </Row>
                        <div className="row d-flex justify-content-center mt-2 mb-4">
                          <Col className="col-lg-6 d-flex justify-content-center">
                            {props.loading ? (
                              <button className="btn lgn-btn" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                {t("Saving...")}
                              </button>
                            ) : (
                              <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>
                                {t("Save")}
                              </button>
                            )}
                          </Col>
                        </div>
                      </div>
                    </>
                  )}
                </Popup>
              </span>
            </div>

            {props.loading ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "650px",
                }}
              >
                Loading data...
              </h3>
            ) : data && data.length > 0 ? (
              <div>
                <CustomPaginationTable
                  data={data}
                  allData={allData}
                  pageCount={pageCount}
                  columns={columns}
                  search={search}
                  setSearch={setSearch}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  loading={props.loading}
                  t={t}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Loading
                <h3 className="loader">
                  <Loader
                    type="MutatingDots"
                    title={"MutatingDots"}
                    color="#232959"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </h3>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.ocpplist,
    fetchAllNotificationListRes: state.evse.fetchAllOcppNotificationDetails,
    fetchEmailDuration: state.evse.fetchEmailDuration,
    updateConfig: state.evse.saveConfigured,
    fetchPrevEmailConfig: state.evse.fetchEmailConfig,
    orgdata: state.operator.organisationsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetch10OcppNotification: (currentPage, pageSize) =>
      dispatch(fetchOCPPNotificationStatusDetailAction(currentPage, pageSize)),
    fetchAllOcppNotification: (currentPage, reportCount) =>
      dispatch(fetchAllOcppNotificationStatusDetailAction(currentPage, reportCount)),
    getEmailDuration: () => dispatch(fetchEmailDuration()),
    saveConfig: (data) => dispatch(saveConfigured(data)),
    fetchConfig: () => dispatch(fetchEmailCofig()),
    getOrg: () => dispatch(allOrgTariffAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OCPPError);
