import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Container } from "react-bootstrap";
import {
  fetchRoleAction,
  fetchPrivilegeAction,
  fetchSubPrivilegeAction,
  fetchNewPrivilegeAction,
} from "../../../../store/actions/mainAction";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import { saveAccessControllAction } from "../../../../store/actions/accessAction";
import PureBreadcrumbs from "../../breadcrums";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import "../access.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Bounce, ToastContainer, toast } from "react-toastify";
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";
import { useLoadScript } from "@react-google-maps/api";
import { Stepper } from "react-form-stepper";

var privilegeArray = [];
var menu = [];

const libraries = ["places"];
function CreateAccess(props) {
  const { t } = useTranslation();
  const [formStep, setFormStep] = useState(0);
  const [address, setAddress] = useState("");
  const [globalPrivilageParentKey, setGlobalPrivilageParentKey] = useState("");
  const [globalSubPrivilageKey, setGlobalSubPrivilageKey] = useState("");
  const [newRole, setNewRole] = useState("");
  const [newPrivilege, setNewPrivilege] = useState([]);
  const [newSubPrivilege, setSubNewPrivilege] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const { role } = JSON.parse(localStorage.getItem("user"));
  const { access, country } = JSON.parse(localStorage.getItem("user"));
  const [selectedRegion, setSelectedRegions] = useState([]);
  const [privilageData, setPrivilageData] = useState([]);
  const [submenu, setSubMenu] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  // const [apiLoaded, setApiLoaded] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //     // Define the initMap function that the Google Maps script will call
  //     window.initMap = () => {
  //         setApiLoaded(true);
  //     };

  //     // Check if the script is already loaded
  //     if (window.google && window.google.maps) {
  //         setApiLoaded(true);
  //     }
  // }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // Reference the static `libraries` array
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Firstname is required"),
    lastName: Yup.string().required("Lastname is required"),
    userName: Yup.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(20, "Username must not exceed 20 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phone: Yup.number().required("Phone number is required").typeError("Phone number is a required"),
    operator: Yup.string().required("Organisation is required"),
    organisationType: Yup.string().required("Organisation Type is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City  is required"),
    state: Yup.string().required("State/Province  is required"),
    country: Yup.string().required("Country is required"),
    Zipcode: Yup.string().required("Zip code is required").typeError("Zip code can only be a number"),
    components: Yup.array().of(
      Yup.object().shape({
        userRole: Yup.string().nullable().required("User role is required"),
      })
    ),
  });

  useEffect(() => {
    props.getrole();
    props.allOrg();
    reset();
  }, []);

  // This logic is used for selecting all sub-privileges by default when you checked privilages
  useEffect(() => {
    const filteredPrivileges = props.allNewPrivileges?.privileges?.filter(
      (item) => item.parent_key === globalPrivilageParentKey
    );

    // Storing an empty array for menu in privilageData state
    const separatedMenu =
      filteredPrivileges &&
      filteredPrivileges.map((item) => {
        const { menu: itemMenu, ...rest } = item;
        return { ...rest, menu: [] };
      });
    privilegeArray = privilegeArray.concat(separatedMenu);
    setPrivilageData((prevState) => prevState.concat(separatedMenu));
    setNewPrivilege(privilegeArray);

    // Extract and store menu array separately
    const extractedMenu = filteredPrivileges && filteredPrivileges.flatMap((item) => item.menu);
    menu = menu.concat(extractedMenu);
    setSubMenu((prevState) => prevState.concat(extractedMenu));
    setSubNewPrivilege(menu);

    // this logic is to check the last privilage you checked after changing roles
    // if (globalPrivilageParentKey) {
    //     if (props.allNewPrivileges && filteredPrivileges && filteredPrivileges.length > 0) {
    //         // console.log("uniquePrivilageData is maintaining its state ..........")
    //         filteredPrivileges.forEach(item => {
    //             const checkbox = document.getElementById(item.parent_key);
    //             if (checkbox) {
    //                 checkbox.checked = false;
    //             }
    //         });
    //     }
    // }

    // this logic is here is used to checked all the sub-privileges after you just checked the privilages
    if (globalPrivilageParentKey && filteredPrivileges && filteredPrivileges.length > 0) {
      filteredPrivileges.forEach((item) => {
        const subPrivilegeCheckboxes = document.querySelectorAll(`.${item.parent_key}`);
        if (subPrivilegeCheckboxes.length > 0) {
          subPrivilegeCheckboxes.forEach((checkbox) => {
            checkbox.checked = true;
          });
        }
      });
    }
  }, [formStep, globalPrivilageParentKey, props.allNewPrivileges]);
  // Logic Ends here

  // This useEffect is used to prevent the checked issue after you select the privilage and change the role
  useEffect(() => {
    if (newRole && props.allNewPrivileges?.privileges?.length > 0) {
      props.allNewPrivileges?.privileges.forEach((item) => {
        const subPrivilegeCheckboxes = document.querySelectorAll(`.${item.parent_key}`);
        if (subPrivilegeCheckboxes.length > 0) {
          subPrivilegeCheckboxes.forEach((checkbox) => {
            checkbox.checked = false;
            checkbox.disabled = true;
          });
        }
      });
    }
    setNewPrivilege([]);
    setSubNewPrivilege([]);
    setSelectedRegions([]);
    privilegeArray = [];
    menu = [];
  }, [newRole, formStep, props.allNewPrivileges]);

  // console.log('Privilage Array:', privilegeArray);
  // console.log(' Menu:', menu);

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
    setNewPrivilege([]);
    setSubNewPrivilege([]);
    // setGlobalEmptyArray([])
    // setGlobalFilterArray([])
    privilegeArray = [];
    menu = [];
  };

  const goToPrevStep = () => {
    setFormStep((cur) => cur - 1);
  };

  // useEffect(() => {
  //     if (props.allNewPrivileges && props.allNewPrivileges.privileges) {
  //         // setPrivileges(props.allNewPrivileges.privileges);
  //         setGlobalSubPrivilageKey("")
  //     }
  // }, [props.allNewPrivileges, globalSubPrivilageKey]);

  const region = ["north", "south", "east", "west"];

  const options = region.map((region) => ({ value: region, label: region }));

  const handleRegionOnchange = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectedValues = selectedOption.map((option) => option.value.toLowerCase());
      // console.log("selectedOption: " , selectedValues)
      setSelectedRegions(selectedValues);
    } else {
      setSelectedRegions([]);
    }
  };

  const handleOnchange = (e) => {
    setGlobalSubPrivilageKey("");
    const singleValue = e.target.value;
    setNewRole(singleValue);
    setValue("userRole", singleValue);
    setNewPrivilege([]);
    setSubNewPrivilege([]);
    setSelectedRegions([]);
    privilegeArray = [];
    menu = [];
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    const result = props.roles && props.roles.roles.filter((rp) => rp.name == singleValue);
    if (singleValue != "" && result != []) {
      setNewRole(result[0].key);
      props.get_new_privileges(result[0].key);
    }
  };

  const handleOnPrivilegechange = (e) => {
    const target = e.target;
    let privilegeKey = target.id;
    // console.log("privilage onchange value", privilegeKey)
    const result =
      props.allNewPrivileges && props.allNewPrivileges.privileges.filter((pk) => pk.parent_key == privilegeKey);

    if (target.checked) {
      // privilegeArray.push({ parent_key: result[0].parent_key, value: result[0].value, icon: result[0].icon, menu: [], header: result[0].header })
      setGlobalPrivilageParentKey(privilegeKey);
    } else {
      const removeIndex = privilegeArray.findIndex((item) => item.parent_key === privilegeKey);
      privilegeArray.splice(removeIndex, 1);
      setGlobalPrivilageParentKey("");
    }
    // console.log("privilegeArray",privilegeArray)
    setNewPrivilege(privilegeArray);
  };

  const handleOnSubPrivilegechange = (e) => {
    const target = e.target;
    let subprivilegeKey = target.id;
    setGlobalSubPrivilageKey(subprivilegeKey);

    const result =
      props.allNewPrivileges &&
      props.allNewPrivileges.privileges.flatMap((privilege) => {
        return privilege.menu && privilege.menu.filter((subPrivilege) => subPrivilege.key === subprivilegeKey);
      });

    if (target.checked) {
      menu.push({ parent_key: result[0].parent_key, key: result[0].key, value: result[0].value, icon: result[0].icon });
      // console.log("menu checked", menu)
    } else {
      for (let i = menu.length - 1; i >= 0; i--) {
        if (menu[i].key === subprivilegeKey) {
          menu.splice(i, 1);
        }
      }
    }

    setSubNewPrivilege(menu);
  };

  // Geolocation placeAutoComplete logic
  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      if (!results || results.length === 0) {
        throw new Error("No results found");
      }

      const latLng = await getLatLng(results[0]);
      const { lat, lng } = latLng;

      const res = await latiLongi(lat, lng);
      if (!res || !res.results || res.results.length === 0) {
        throw new Error("No response from latiLongi");
      }

      setError("");
      setAddress(address);
      setValue("address", address);
      setValue("latitude", lat);
      setValue("longitude", lng);

      let city, state, country, postCode, countryCode;
      res.results[0].address_components.forEach((component) => {
        component.types.forEach((type) => {
          switch (type) {
            case "locality":
              city = component.long_name;
              setValue("city", city);
              break;
            case "administrative_area_level_1":
              state = component.long_name;
              setValue("state", state);
              break;
            case "country":
              country = component.long_name;
              countryCode = component.short_name;
              setValue("country", country);
              break;
            case "postal_code":
              postCode = component.long_name;
              setValue("Zipcode", postCode);
              break;
            default:
              break;
          }
        });
      });
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to retrieve location details. Please try again.");
    }
  };

  const onSubmit = async (data) => {
    // here we are using Set Data structure to remove the duplicates from the array with the help of parent_key and key
    const uniqueKeys = new Set();
    const uniqueParentkey = new Set();

    const uniquePrivilageData =
      newPrivilege &&
      newPrivilege.reduce((acc, curr) => {
        if (!uniqueParentkey.has(curr.parent_key)) {
          uniqueParentkey.add(curr.parent_key);
          acc.push(curr);
        }
        return acc;
      }, []);

    const uniqueMenuData =
      newSubPrivilege &&
      newSubPrivilege.reduce((acc, curr) => {
        if (!uniqueKeys.has(curr.key)) {
          uniqueKeys.add(curr.key);
          acc.push(curr);
        }
        return acc;
      }, []);
    // remove duplicates logic Ends here...........

    let cartCopy = [...uniquePrivilageData];
    uniquePrivilageData.forEach((el) => {
      let submenu = [];
      uniqueMenuData.forEach((sel) => {
        if (el.parent_key === sel.parent_key) {
          submenu.push(sel);
        }
      });
      el.menu = submenu;
      setNewPrivilege(cartCopy);
    });

    const emptysubPrivilege =
      newPrivilege &&
      newPrivilege.filter((privilege) => {
        return privilege.menu && privilege.menu.length === 0;
      });
    // console.log("emptysubPrivilege",emptysubPrivilege);

    const filterPrivilege =
      newPrivilege &&
      newPrivilege.filter((privilege) => {
        return privilege.menu && privilege.menu.length > 0;
      });
    // console.log("filterPrivilege",filterPrivilege);

    if (emptysubPrivilege.length > 0) {
      alert("Please select the sub-privileges");
    } else if (filterPrivilege != "") {
      // console.log("data", data, selectedRegion, filterPrivilege)
      props.saveUser(data, selectedRegion, filterPrivilege);
    } else if (filterPrivilege.length === 0) {
      alert("Please select at-least One Privilege and sub-privileges");
    }
  };

  useEffect(() => {
    if (props.response.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("New access has been created", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.response.statuscode === 405) {
      setIsToastVisible(true);
      toast.error(`❌ ${props.response.result}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.response]);
  // console.log("================", props.response.statuscode);

  const renderButton = () => {
    if (formStep > 2) {
      return undefined;
    } else if (formStep === 1) {
      return (
        <div>
          <div className="row">
            <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-start mt-2">
              <button className="btn lgn-btn " type="button" disabled={!isValid} onClick={goToPrevStep}>
                {t("Back")}
              </button>
            </Col>
            {newRole === "RM" ? (
              <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-end mt-2">
                {props.loading !== true ? (
                  <button
                    className="btn lgn-btn"
                    type="submit"
                    style={{ position: "static" }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={selectedRegion.length === 0}
                  >
                    {t("CREATE NEW USER")}
                  </button>
                ) : (
                  <button className="btn lgn-btn" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {t("Saving...")}
                  </button>
                )}
              </Col>
            ) : (
              <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-end mt-2">
                {props.loading !== true ? (
                  <button
                    className="btn lgn-btn"
                    type="submit"
                    style={{ position: "static" }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                  >
                    {t("CREATE NEW USER")}
                  </button>
                ) : (
                  <button className="btn lgn-btn" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {t("Saving...")}
                  </button>
                )}
              </Col>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-grid gap-2 d-md-flex justify-content-md-end p-3">
          <Col>
            {formStep > 0 ? (
              <button className="btn lgn-btn " type="button" onClick={goToPrevStep}>
                {t("Back")}
              </button>
            ) : (
              ""
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <button className="btn lgn-btn " type="button" onClick={completeFormStep} disabled={!isValid}>
              {t("PROCEED TO NEXT STEP")}
            </button>
          </Col>
        </div>
        // <div className="d-grid gap-2 d-md-flex justify-content-md-end p-3">
        //     <Col lg={6} className='d-flex justify-content-end'>
        //         <button className=" lgn-btn btn " type="button" onClick={completeFormStep} disabled={!isValid}  >PROCEED TO NEXT STEP</button>
        //     </Col>
        // </div>
      );
    }
  };

  const handleOrganisation = (e) => {
    const code = e.value;
    console.log("operator", code);
    setValue("operator", code);
  };

  const organisationOptions =
    props.organisationlist &&
    props.organisationlist.organisations?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  return (
    <Container fluid>
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <br />

      <div className=" tariffcard">
        {/* <!-- start step indicators --> */}
        {/* <div className="form-header d-flex mb-4">
          <span className="stepIndicator">{t("Add Details")}</span>
          <span className="stepIndicator">{t("Assign Roles")}</span>
        </div> */}
        <Stepper steps={[{ label: "Add Details" }, { label: "Assign Roles" }]} activeStep={formStep} />
        {/* <!-- end step indicators --> */}
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            {formStep === 0 && (
              <Card className="tariffcard">
                <Card.Body>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("First Name")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          autoFocus
                          type="text"
                          className={`form-control gray-border ${errors.firstName ? "is-invalid" : ""}`}
                          name="firstName"
                          id="floatingInputGridfirstname"
                          placeholder={t("First Name")}
                          {...register("firstName")}
                        />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Last Name")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.lastName ? "is-invalid" : ""}`}
                          name="lastName"
                          id="floatingInputGridlastName"
                          placeholder={t("Last Name")}
                          {...register("lastName")}
                        />
                        <div className="invalid-feedback">{errors.lastName?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Username")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.userName ? "is-invalid" : ""}`}
                          name="userName"
                          id="floatingInputGridusername"
                          placeholder={t("Username")}
                          {...register("userName")}
                        />
                        <div className="invalid-feedback">{errors.userName?.message}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Temporary Password")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type={passwordType}
                          className={`form-control gray-border ${errors.password ? "is-invalid" : ""}`}
                          name="password"
                          id="floatingInputGridpassword"
                          placeholder={t("Temporary Password")}
                          {...register("password")}
                        />
                        <span className="p-viewer">
                          <i className="btn" onClick={togglePassword}>
                            {passwordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                          </i>
                        </span>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Email")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="email"
                          className={`form-control gray-border ${errors.email ? "is-invalid" : ""}`}
                          name="email"
                          id="floatingInputGridemail"
                          placeholder={t("Email")}
                          {...register("email")}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Phone No.")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control gray-border ${errors.phone ? "is-invalid" : ""}`}
                          name="phone"
                          id="floatingInputGridphone"
                          placeholder={t("Phone No.")}
                          {...register("phone")}
                        />
                        <div className="invalid-feedback">{errors.phone?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Organisation")} <span className="mandatory-field">*</span>
                        </label>
                        <Select
                          options={organisationOptions ? organisationOptions : []}
                          className={`${errors.operator ? "is-invalid" : ""}`}
                          name="operator"
                          id="floatingInputGridoperator"
                          placeholder={getValues("operator") ? getValues("operator") : "Select Organisation"}
                          styles={DarkThemeStyles}
                          menuPortalTarget={document.body}
                          onChange={handleOrganisation}
                        />
                        <div className="invalid-feedback">{errors.operator?.message}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Organisation Type")} <span className="mandatory-field">*</span>
                        </label>
                        <select
                          className={`form-select form-control ${errors.organisationType ? "is-invalid" : ""}`}
                          name="unit"
                          id="floatingSelectGridorganisationType"
                          {...register("organisationType")}
                        >
                          <option value="">{t("Select Organisation Type")}</option>
                          <option value="Limited Liability Company">{t("Limited Liability Company")}</option>
                          <option value="Co-operative Company">{t("Co-operative Company")}</option>
                          <option value="Unlimited Company">{t("Unlimited Company")}</option>
                        </select>
                        <div className="invalid-feedback">{errors.organisationType?.message}</div>
                      </div>
                    </div>
                  </div>
                  {/*Address */}
                  <div className="row g-2">
                    <div className="col-md px-3">
                      <div className="form-floating">
                        <label>{t("Enter Address")}</label>
                        {error && <div className="alert alert-danger">{error}</div>}
                        {isLoaded ? (
                          <PlacesAutocomplete
                            value={address}
                            onChange={(value) => (setValue("address", value), setAddress(value))}
                            onSelect={handleSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: `location-search-input gray-border ${
                                      errors.address ? "is-invalid" : ""
                                    }`,
                                    style: { fontSize: 14, borderRadius: 2, height: 37 },
                                  })}
                                  name="address"
                                />
                                <div className="invalid-feedback">{errors.address?.message}</div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}

                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                    const style = suggestion.active
                                    ? { backgroundColor: 'var(--button-text-color)', cursor: 'pointer' }
                                    : { backgroundColor: 'var(--button-text-color) ', cursor: 'pointer' };
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        ) : (
                          <div>Loading Google Maps...</div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*Address */}
                  <div className="row py-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Country")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.country ? "is-invalid" : ""}`}
                          name="country"
                          id="floatingInputCountry"
                          placeholder={t("Country")}
                          {...register("country")}
                        />
                        <div className="invalid-feedback">{errors.country?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("State")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.state ? "is-invalid" : ""}`}
                          name="state"
                          id="floatingInputState"
                          placeholder={t("State")}
                          {...register("state")}
                        />
                        <div className="invalid-feedback">{errors.state?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("City")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.city ? "is-invalid" : ""}`}
                          name="city"
                          id="floatingInputCity"
                          placeholder={t("City")}
                          {...register("city")}
                        />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Zipcode")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.Zipcode ? "is-invalid" : ""}`}
                          name="zipcode"
                          id="floatingInputzip"
                          placeholder={t("Zipcode")}
                          {...register("Zipcode")}
                        />
                        <div className="invalid-feedback">{errors.Zipcode?.message}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                </Card.Body>
              </Card>
            )}

            {formStep === 1 && (
              <>
                <Card key={0} className="tariffcard">
                  <Card.Body>
                    <div className="row d-flex justify-content-between">
                      <div className="col-lg-12 col-sm-12">
                        <Card.Text style={{ fontSize: "14px", marginLeft: "1.2rem" }}>
                          <b>
                            {t("Roles")} <span className="mandatory-field">*</span>
                          </b>
                        </Card.Text>
                        <div className="ml-3 mr-3">
                          {role === "Admin" ? (
                            <div className="form-floating" style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                              <select
                                className={`form-select form-control ${
                                  errors.components?.[0]?.userRole ? "is-invalid" : ""
                                }`}
                                name={`components[${0}].userRole`}
                                id="floatingSelectGridrole"
                                {...register(`components[${0}].userRole`, { onChange: (e) => handleOnchange(e) })}
                              >
                                <option value="">{t("Select Role")}</option>
                                {props.roles &&
                                  props.roles.roles &&
                                  props.roles.roles.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <div className="invalid-feedback">{errors.components?.[0]?.userRole?.message}</div>
                            </div>
                          ) : (
                            <div className="form-floating">
                              <select
                                className={`form-select form-control ${
                                  errors.components?.[0]?.userRole ? "is-invalid" : ""
                                }`}
                                name={`components[${0}].userRole`}
                                id="floatingSelectGridrole"
                                {...register(`components.${0}.userRole`, { onChange: (e) => handleOnchange(e) })}
                              >
                                <option value="">{t("Select Role")}</option>
                                {props.roles &&
                                  props.roles.roles &&
                                  props.roles.roles.slice(2).map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <div className="invalid-feedback">{errors.components?.[0]?.userRole?.message}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                    {newRole === "RM" && (
                      <Card  className="tariffcard" style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                        <Card.Body>
                          <label>
                            {t("Select Regions")} <span className="mandatory-field">*</span>
                          </label>
                          <Select
                            closeMenuOnSelect={true}
                            options={options || []}
                            isMulti
                            // isOptionDisabled={isOptionDisabled}
                            onChange={handleRegionOnchange}
                            placeholder="Select Region"
                          />

                          {selectedRegion.length === 0 && (
                            <p className=" text-danger">Please select the region for Regional Manager</p>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                    <div className="col-lg-12">
                      <Card key={1} className="tariffcard">
                        <Card.Body>
                          <div>
                            <Row className=" d-flex justify-content-center">
                              <h4 style={{ textAlign: "center" }}>{t("Select Privileges and Sub Privileges")}</h4>
                            </Row>
                            <br />
                            <br />
                            <Row className="">
                              <div className="col-3 d-flex justify-content-center">
                                <h4>{t("Privileges")}</h4>
                              </div>
                              <div className="col-9 d-flex justify-content-around">
                                <h4>{t("Sub-Privileges")}</h4>
                              </div>
                            </Row>
                            <>
                              {props.allNewPrivileges &&
                                props.allNewPrivileges.privileges &&
                                props.allNewPrivileges.privileges.map((item, i) => (
                                  <Row>
                                    <Col lg={3}>
                                      <div className="my_Privilagecontainer">
                                        <div className="form-check form-switch" key={item.parent_key}>
                                          <input
                                            className={`form-check-input`}
                                            style={{ marginTop: "1.1rem" }}
                                            type="checkbox"
                                            id={item.parent_key}
                                            onChange={(e) => handleOnPrivilegechange(e)}
                                          />
                                          <label
                                            style={{ marginLeft: "1rem", marginTop: "0.9rem" }}
                                            className="form-check-label"
                                            htmlFor={item.parent_key}
                                          >
                                            {item.value}
                                          </label>
                                        </div>
                                      </div>
                                    </Col>

                                    <Col lg={9}>
                                      <div className="my_SubPrivilagecontainer" id="subcontainer">
                                        {item.menu &&
                                          item.menu.map((sub_item, index) => (
                                            <div className="form-check form-switch mr-2 " key={sub_item.key}>
                                              &nbsp;
                                              <input
                                                className={`form-check-input ${item.parent_key}`}
                                                type="checkbox"
                                                id={sub_item.key}
                                                onChange={(e) => handleOnSubPrivilegechange(e)}
                                                disabled={globalPrivilageParentKey !== sub_item.parent_key}
                                              />
                                              &nbsp;
                                              <label
                                                style={{ marginLeft: "0.5rem" }}
                                                className="form-check-label"
                                                htmlFor={sub_item.key}
                                              >
                                                {sub_item.value}
                                              </label>
                                            </div>
                                          ))}
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                            </>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )}

            {renderButton()}
            <br />
            <br />
          </form>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.access.isLoading,
    roles: state.main.roles,
    allNewPrivileges: state.main.getNewPrivilages,
    privilegelist: state.main.privileges,
    subprivilegelist: state.main.subprivileges,
    organisationlist: state.operator.organisationsList,
    response: state.access.saveuser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getrole: () => dispatch(fetchRoleAction()),
    getprivileges: (role) => dispatch(fetchPrivilegeAction(role)),
    getsubprivileges: (role) => dispatch(fetchSubPrivilegeAction(role)),
    allOrg: () => dispatch(allOrgTariffAction()),
    saveUser: (user, selectedRegion, privilege) => dispatch(saveAccessControllAction(user, selectedRegion, privilege)),
    get_new_privileges: (role) => dispatch(fetchNewPrivilegeAction(role)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccess);

const latiLongi = (a, b) => {
  return Geocode.fromLatLng(a, b).then((res) => {
    return res;
  });
};
