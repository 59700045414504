import React from 'react'
import { useForm } from "react-hook-form";
import { Card, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import PureBreadcrumbs from "../../../breadcrums";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { singleRoamingPartner, updateRoamingPartner } from "../../../../../store/actions/ocpiAction";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const UpdateRoamingPartner = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.getSinglerData(id);
    }, []);
    const partnerdata = props.fetchSingleDetails && props.fetchSingleDetails

    const [address, setAddress] = useState("")
    const handleAddressChange = address => {
        setAddress(address);
    };
    // GeoLocation logic
    const handleSelect = address => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            const { lat, lng } = latLng
            const res = latiLongi(lat, lng)
            res.then(respon => {
                //const address = respon.results[0].formatted_address;
                setAddress(address)
                setValue("address", address);
                setValue("latitude", lat);
                setValue("longitude", lng);
                let city, state, country, postCode;
                for (let i = 0; i < respon.results[0].address_components.length; i++) {
                    for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                        switch (respon.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = respon.results[0].address_components[i].long_name;
                                setValue("city", city)
                                break;
                            case "administrative_area_level_1":
                                state = respon.results[0].address_components[i].long_name;
                                setValue("state", state)
                                break;
                            case "country":
                                country = respon.results[0].address_components[i].long_name;
                                setValue("country", country)
                                break;
                            case "postal_code":
                                postCode = respon.results[0].address_components[i].long_name;
                                setValue("Zipcode", postCode)
                                break;
                            default:
                        }
                    }
                }
            })

        })
    }

    const addPartnerSchema = Yup.object().shape({
        partner_name: Yup.string().required('Name is required'),
        country_code: Yup.string(),
        address: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        country: Yup.string(),
        party_id: Yup.string().required('Party ID is required'),
        url: Yup.string().required('URL ID is required'),
        tokenA: Yup.string().required('TokenA is required'),
    });

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(addPartnerSchema) });

    // console.log(partnerdata)
    useEffect(() => {
        if (partnerdata) {
            setValue('partner_name', partnerdata.partner_name);
            setValue('country_code', partnerdata.country_code);
            setValue('address', partnerdata.address);
            setAddress(partnerdata.address)
            setValue('city', partnerdata.city);
            setValue('state', partnerdata.state);
            setValue('country', partnerdata.country);
            setValue('party_id', partnerdata.party_id);
            setValue('url', partnerdata.url);
            setValue('tokenA', partnerdata.tokenA)
        }
    }, [partnerdata]);

    const onSubmit = async data => {
        props.updatePartner(id, data)
    }


    useEffect(() => {
        if (props.update.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Roaming partner has been updated.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.update]);

    if (redirect) {
        return <Redirect to='/dashboard/view_partner' />;
    }

    // if (props.update.statuscode === 200) {
    //     alert("Roaming partner has been updated.")
    //     return <Redirect to='/dashboard/view_partner' />;
    // }

    return (
        <>
            <Container fluid>
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <br />
                <Row>
                    <Col xs={12} md={1}>
                    </Col>
                    <Col xs={12} md={10}>
                        <br />
                        <Card className="tariffcard">
                            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>Update Roaming Partner</b></Card.Text>
                            <Card.Body>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="form-floating">
                                        <label>Partner Name <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.partner_name ? 'is-invalid' : ''}`} name="partner_name" id="floatingInputGridpartner_name" placeholder="Enter Name" {...register("partner_name")} />
                                        <div className="invalid-feedback">{errors.partner_name?.message}</div>
                                    </div>
                                    <div className="row g-2 py-3">
                                        <div className="col-md">
                                            <div className="form-floating">
                                                <label> Enter Address</label>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleAddressChange}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Address ...',
                                                                    className: "location-search-input gray-border",
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                        ? { backgroundColor: 'var(--button-text-color)', cursor: 'pointer' }
                                                                        : { backgroundColor: 'var(--button-text-color) ', cursor: 'pointer' };
                                                                    return (
                                                                        <div className="input-suggestion"
                                                                            {...getSuggestionItemProps(suggestion, {

                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span className="material-symbols-outlined"></span><span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}


                                                </PlacesAutocomplete>
                                                <div className="invalid-feedback">{errors.address?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-floating">
                                                <label>Country Code </label>
                                                <input type="text" className={`form-control gray-border ${errors.country_code ? 'is-invalid' : ''}`} name="country_code" id="floatingInputGridnumber" placeholder="Enter country code" {...register("country_code")} />
                                                <div className="invalid-feedback">{errors.country_code?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-floating">
                                                <label>Country </label>
                                                <input type="text" className={`form-control gray-border ${errors.country ? 'is-invalid' : ''}`} name="country" id="floatingInputzip" placeholder="Enter Country"  {...register("country")} />
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </div>
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>

                                        <div className="col-md">
                                            <div className="form-floating">
                                                <div className="form-floating">
                                                    <label>State </label>
                                                    <input type="text" className={`form-control gray-border ${errors.state ? 'is-invalid' : ''}`} name="state" id="floatingInputzip" placeholder="Enter State/Province"  {...register("state")} />
                                                    <div className="invalid-feedback">{errors.state?.message}</div>
                                                </div>
                                                <div className="invalid-feedback">{errors.state?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-floating">
                                                <label>City</label>
                                                <input type="text" className={`form-control gray-border ${errors.city ? 'is-invalid' : ''}`} name="city" id="floatingInputzip" placeholder="Enter City"  {...register("city")} />
                                                <div className="invalid-feedback">{errors.city?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-floating py-2">
                                        <label>URL <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.url ? 'is-invalid' : ''}`} name="url" id="floatingInputGridurl" placeholder="Enter OCPI URL" {...register("url")} />
                                        <div className="invalid-feedback">{errors.url?.message}</div>
                                    </div>
                                    <div className="form-floating py-2">
                                        <label>Party ID <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.party_id ? 'is-invalid' : ''}`} name="party_id" id="floatingInputGridurl" placeholder="Enter Party ID" {...register("party_id")} />
                                        <div className="invalid-feedback">{errors.party_id?.message}</div>
                                    </div>
                                    <div className="form-floating py-2">
                                        <label>Token <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.tokenA ? 'is-invalid' : ''}`} name="party_id" id="floatingInputGridtoken" placeholder="Enter Token" {...register("tokenA")} />
                                        <div className="invalid-feedback">{errors.tokenA?.message}</div>
                                    </div><br />
                                    <div>
                                        <Row className='d-flex justify-content-end'>
                                            {/* <Col>
                                                <button className="lgn-btn btn" style={{ width: "13rem", height: "auto" }} type="button" onClick={() => {
                                                    reset({
                                                        partner_name: '', country_code: '', address: setAddress(''), city: '', state: '', country: '',
                                                        party_id: '', url: ''
                                                    });
                                                }}>Reset</button>
                                            </Col><br /> */}
                                            <Col className='d-flex justify-content-end'>
                                                {props.loading ? <button className="btn lgn-btn" style={{ width: "auto", height: "auto" }} type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </button> :
                                                    <button className="lgn-btn btn" style={{ width: "auto", height: "auto" }} type="submit" onClick={handleSubmit(onSubmit)}>Update Roaming partner</button>
                                                }
                                            </Col>
                                        </Row>

                                    </div><br />
                                </form>
                            </Card.Body>
                            <br /> <br />
                        </Card>

                    </Col>
                    <Col xs={12} md={1}>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        update: state.ocpi.updatepartner,
        fetchSingleDetails: state.ocpi.fetchsinglepartner,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updatePartner: (id, data) => dispatch(updateRoamingPartner(id, data)),
        getSinglerData: (id) => dispatch(singleRoamingPartner(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateRoamingPartner))

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}