import { t } from "i18next";
import React from "react";
import ApexCharts from "react-apexcharts";
import { Card } from "react-bootstrap";

function CustomMonthlyEnergy({ month_total, loading }) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const monthlyData = month_total?.data || [];
  const monthlyEnergy = monthlyData?.map((entry) => Math.round(entry.energy));
  const monthlyLabels = monthlyData?.map((entry) => monthNames[entry.ID.number - 1]);

  const chartOptions = {
    chart: { type: "area", height: 350 },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
         }
       },  
      yaxis: {
        lines: { 
          show: false
         }
       },   
    },
    xaxis: { categories: monthlyLabels,  labels: {
      style: {
        colors: "#FFFFFF", // Replace with the color you want for Y-axis content
      },
    },    },
    yaxis: [
      {
        title: {
          text: "Monthly Consumption",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: (val) => val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        },
        labels: {
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },     
      },
    ],
    dataLabels: { enabled: false },
    stroke: { curve: "smooth" },
    colors: ["#0BCF6B"],
    fill: {
      type: "gradient",
      gradient: { shadeIntensity: 1, opacityFrom: 0.7, opacityTo: 0.9, stops: [0, 100] }
    },
    series: [{ name: "Monthly Consumption", data: monthlyEnergy }],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
      x: {
        formatter: (val) => val,
      },
    },
  };

  return (
    <Card className="statuscard">
      <Card.Title className="text-center">{t("Monthly Energy Graph")}</Card.Title>
      <Card.Body>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center" style={{ height: "250px" }}>
            <h3>Loading data...</h3>
          </div>
        ) : monthlyData.length > 0 ? (
          <ApexCharts options={chartOptions} series={chartOptions.series} type="area" height={250} />
        ) : (
          <div className="d-flex align-items-center justify-content-center" style={{ height: "250px" }}>
            <h3>{t("No data found")}</h3>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default CustomMonthlyEnergy;
