import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Row, Card, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "./login/login.css"
import { updateUserPasswordAction } from '../../store/actions/accessAction';
import { useTranslation } from 'react-i18next';

function ChangePassword(props) {
    const { t } = useTranslation();
    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string().required('Confirm Password is required').min(6, 'Confirm Password must be at least 6 characters').max(40, 'Confirm Password must not exceed 40 characters'),
    });
    const { register, handleSubmit, getValues, formState: { errors } } = useForm({ resolver: yupResolver(changePasswordSchema), mode: "all" });

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const onSubmit = (record) => {
        props.updatePassData(record)
    };

    useEffect(() => {
        if (props.response.statuscode == 405) {
            alert(props.response.error)
        }
        if (props.response.statuscode == 200 && props.response.ispasswordupdated === true) {
            alert(props.response.result)
            window.location.replace('/dashboard');
        }

    }, [props.response])

    return (

        <div className='login-background'>
            <Card className='changepassbox-form customercard'>
                <Card.Body>
                    <Row className='d-flex justify-content-center'>
                        <span className='changepass'>
                            {t("Change your password")}
                        </span>
                    </Row>
                    <br />
                    <br />
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-floating">
                            <input type={passwordVisible ? "text" : "password"} autoFocus className={`form-control ${errors.password ? 'is-invalid' : ''}`} name="password" id="floatingInputChangeNewPassword" placeholder={t("Enter the new password")} aria-label={t("Enter the new password")} {...register("password")} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div><br />

                        <div className="form-floating">
                            <input type="password" className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} name="confirmPassword" id="floatingInputConfirmNewPassword" placeholder={t("Re-enter the new password")} aria-label={t("Re-enter the new password")}  {...register("confirmPassword")} />
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                        </div>
                        <br />
                        <br />
                        <Row className='d-flex justify-content-center'>
                            <Button variant="success" className='lgn-btn' onClick={handleSubmit(onSubmit)}>{t("Update Password")}
                            </Button>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>

    )


}



const mapStateToProps = (state) => {
    return {
        response: state.access.changePassword,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updatePassData: (record) => dispatch(updateUserPasswordAction(record))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
